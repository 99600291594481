<template>
  <v-app-bar color="primary" app>
    <v-app-bar-nav-icon class="pa-2" @click="appBarLogoClick">
      <v-img src="@/assets/img/logo-inverse.png" width="24" alt="さくらフル活用 アイコン"></v-img>
    </v-app-bar-nav-icon>
    <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
  import { useRoute } from "vue-router";
  import { getPageTitle } from '@/mixins';

  export default {
    name: 'AppHeader',
    data: () => ({
      appTitle: undefined,
    }),
    watch: {
      '$route': function( to, from ){
        if( to.path != from.path ){
          this.appTitle = getPageTitle( to.meta.title )
          document.title = this.appTitle
        }
      }
    },
    mounted(){
      this.appTitle = getPageTitle( useRoute().meta.title )
      document.title = this.appTitle
    },
    methods: {
      updateTitle: function( title ){
        this.appTitle = getPageTitle( title )
        document.title = this.appTitle
      },
      onPageItemClick: function( path ){
        if( path != this.$router.currentRoute.path ){
          this.$router.push( path )
        }
      },
      appBarLogoClick: function(){
        this.$router.push( "/" )
      }
    },
  }
</script>
