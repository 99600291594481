export const getPageTitle = pathPageTitle => {
  const defaultPageTitle = 'さくらフル活用'
  const pageTitle = !pathPageTitle ? defaultPageTitle : pathPageTitle
  return pageTitle
}

export const getBreadCrumbs = ( routes, breadCrumbsTree ) => {
  return breadCrumbsTree.map( function( item, index ){
    const route = routes.filter( it => { return it.name == item } ).shift()
    return {
      title: ( index == 0 ) ? 'ホーム' : route.meta.title,
      disabled: ( index == breadCrumbsTree.length - 1 ),
      href: route.path
    }
  } )
}

export const getCopyRights = () => {
  const copyRights = "\u00A9" + "2008" + "\u2013" + ( new Date() ).getFullYear() + " さくらフル活用/Hi.N.E All Rights Reserved."
  return copyRights
}

export const setTitle = pathTitle => {
  const siteTitle = 'さくらフル活用'
  const pageTitle = !pathTitle ? siteTitle : pathTitle + ' - ' + siteTitle
  return ( window.document.title = pageTitle )
}

export const setDescription = pathDescription => {
  const defaultDescription = 'さくらのレンタルサーバを中心に、さくらインターネットのサービス全般のまとめや更新情報、FAQなどの、さくらユーザーさん達にとって便利と思われるようなコンテンツを扱っています。'
  const description = pathDescription ? pathDescription : defaultDescription
  document
    .querySelector( "meta[name='description']" )
    .setAttribute( 'content', description )
}

export const setKeywords = pathKeywords => {
  const defaultKeywords = 'さくらインターネット,さくらのレンタルサーバ,DSad,アフィリエイト,活用方法,更新情報,FAQ,Q&amp;A'
  const keywords = pathKeywords ? pathKeywords : defaultKeywords
  document
    .querySelector( "meta[name='keywords']" )
    .setAttribute( 'content', keywords )
}

export const globalMixins = {
  methods: {
    getPageTitle,
    getBreadCrumbs,
    getCopyRights,
    setTitle,
    setDescription,
    setKeywords
  }
}
