// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#cc9966',
          'primary-darken-1': '#cc9966',
          secondary: '#ffcccc',
          'secondary-darken-1': '#ffcccc',
        },
      },
    },
  },
})
