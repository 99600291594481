<template>
  <div>
    <div class="d-flex">
      <v-container class="py-0">
        <v-row>
          <bread-crumbs :items="breadCrumbs"></bread-crumbs>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" md="8" lg="9" xl="10">
            <h3 class="text-h6 mb-4">
              さくらのレンタルサーバ まとめ
            </h3>
            <p class="text-body-1">
              このページではさくらのレンタルサーバ関連の情報などをまとめています。
            </p>
            <p class="text-body-1 red--text font-weight-bold">
              ※ このページは不定期に更新を行っていくと思います。
            </p>
            <h3 class="text-h6 mb-4">
              公式サポート情報
            </h3>
            <p class="text-body-1">
              <a href="https://help.sakura.ad.jp/" target="_blank">さくらのサポート情報</a>（外部リンク）<br />
              <a href="https://help.sakura.ad.jp/category/rs/" target="_blank">レンタルサーバ 公式サポート情報</a>（外部リンク）<br />
            </p>
            <h3 class="text-h6 mb-4">
              サービス利用方法 まとめ
            </h3>
            <p class="text-body-1">
              さくらのレンタルサーバ利用開始方法については、<a href="https://help.sakura.ad.jp/206106542/" target="_blank">公式のスタートアップページ</a>を参考にすれば大丈夫です。
            </p>
            <p class="text-body-1">
              特に、ホームページやWebサイトの作成について全くの初心者の方は、公式サポートが親切丁寧な<a href="https://sakuramarina.com/" target="_blank">サポートページ</a>を用意してくれています。<br />
              「<a href="https://sakuramarina.com/profile/" target="_blank">まりな</a>」という名前の可愛い公式キャラクター（Twitter: <a href="https://twitter.com/sakura_ope" target="_blank">@sakura_ope</a>）が丁寧に教えてくれるので、そちらを参考にしちゃうと良いです。
            </p>
            <p class="text-body-1">
              余談ですが、まりなさんは、刺さる人には刺さるかわいいキャラクターだと思います。
            </p>
            <h3 class="text-h6 mb-4">
              当サイトの役割について
            </h3>
            <p class="text-body-1">
              日を追うごとに公式サポート情報が充実してきていて、モヤモヤしてます。
            </p>
            <p class="text-body-1">
              当サイトでは今後、公式サポートには載っていないけれど、ちょっと役に立つようなコツやTipsなどを中心に載せていく予定です。
            </p>
          </v-col>
          <side-bar></side-bar>
        </v-row>
      </v-container>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import BreadCrumbs from '@/components/BreadCrumbs';
  import SideBar from '@/components/SideBar';
  import AppFooter from '@/components/AppFooter';

  export default defineComponent({
    name: 'Rental',
    components: {
      BreadCrumbs,
      SideBar,
      AppFooter
    },
    data: () => ({
      breadCrumbs: [
        "Home",
        "Rental"
      ]
    }),
  });
</script>
